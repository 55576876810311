<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">营收数据</span>
      </div>
      <div>
        <el-form ref="searchForm" size="small" :inline="true">
          <el-form-item label="月份选择：">
            <span slot="label"><b>月份：</b></span>
            <el-date-picker
                v-model="rangYm"
                type="monthrange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
                format="yyyy-MM"
                value-format="yyyy-MM"
                :picker-options="pickerOptions" @change="changeMonths">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div class="default-table">
        <el-table
            :data="tableData"
            :row-class-name="rowClassName"
            :summary-method="getSummaries"
            show-summary
            border
            style="width: 100%">
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table
                  :data="props.row.items"
                  :row-class-name="rowGridName"
                  :summary-method="getSummaries"
                  show-summary
                  border
                  style="width: 100%">
                <el-table-column
                    prop="index"
                    width="64"
                    label="序号" align="center">
                </el-table-column>
                <el-table-column
                    prop="time_month"
                    width="100"
                    label="月份" align="center">
                </el-table-column>
                <template v-for="(column,index) in columns">
                  <el-table-column :prop="`${column.value}`" :key="index" :label="`${column.title}`"
                                   :min-width="`${column.width}`" align="right" header-align="center" sortable>
                    <template slot-scope="scope">
                      <span v-if="['income_2'].indexOf(column.value)>-1">
                        {{ numberFormat(scope.row[column.value]) || 0 }}
                      </span>
                      <span class="money" v-else>{{ moneyFormat(scope.row[column.value] || 0) }}</span>
                    </template>
                  </el-table-column>
                </template>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column
              prop="index"
              width="64"
              label="序号" align="center">
          </el-table-column>
          <el-table-column
              prop="platform_name"
              label="平台"
              min-width="140" align="center" sortable>
            <template slot-scope="scope">
              <div class="platform">
                <div class="platform-logo">
                  <el-image
                      style="width: 22px; height: 22px"
                      :src="`${scope.row.logo_url?scope.row.logo_url:'https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png'}`"
                      fit="contain">
                  </el-image>
                </div>
                <div class="platform-title">
                  <span>{{ scope.row.platform_name }} </span>
                  <el-tag size="mini" type="danger" v-if="scope.row.has==='N'">无账号</el-tag>
                </div>
              </div>

            </template>
          </el-table-column>

          <template v-for="(column,index) in columns">
            <el-table-column :prop="`${column.value}`" :key="index" :label="`${column.title}`"
                             :min-width="`${column.width}`" align="right" header-align="center" sortable>
              <template slot-scope="scope">
                <span v-if="['income_2'].indexOf(column.value)>-1">
                  {{ numberFormat(scope.row[column.value]) || 0 }}</span>
                <span class="money" v-else>{{ moneyFormat(scope.row[column.value] || 0) }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <el-divider/>
      <div>
        <el-form size="small" :inline="true">
          <el-form-item label="年份选择：">
            <span slot="label"><b>【总营收】年份：</b></span>
            <el-date-picker
                v-model="year"
                type="year"
                format="yyyy"
                value-format="yyyy"
                placeholder="选择年" @change="changeYear" clearable>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <platforms-radio-group :show-all="true" size="mini" :platform-code="searchCondition.platform_code"
                                   @choicePlatform="choicePlatform"></platforms-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-row>
        <el-col :span="24">
          <div id="revenue_chart"></div>
        </el-col>
      </el-row>
      <el-divider/>
      <el-row>
        <el-col :span="24">
          <platform-pie-chart :artist-id="artistId"/>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { numberFormat } from "@/utils/utils";
import * as echarts from 'echarts';
import elementResizeDetector from "element-resize-detector";
import PlatformsRadioGroup from "@/pages/platform/components/PlatformsRadioGroup";
import PlatformPieChart from "@/pages/dashboard/revenue/PlatformPieChart";

export default {
  name: "ArtistRevenueData",
  props: {
    artistId: {
      type: String,
    },
  },
  components: {
    elementResizeDetector, echarts, PlatformsRadioGroup, PlatformPieChart
  },
  data() {
    return {
      loading: false,
      rangYm: [],
      income_types: [
        { id: 1, name: '商务营收' },
        { id: 2, name: '执行商单数' },//数值
        { id: 3, name: '短视频佣金' },
        { id: 4, name: '直播佣金' },
        { id: 5, name: '直播坑位费' },
        { id: 6, name: '授权收入' },
        { id: 7, name: '推广花费' },
      ],
      tableData: [],
      columns: [
        { value: 'income_total', title: '总营收', width: 100 },
        { value: 'income_1', title: '商务营收', width: 100 },
        { value: 'income_2', title: '执行商单数', width: 100 },
        { value: 'income_3', title: '短视频佣金', width: 100 },
        { value: 'income_4', title: '直播佣金', width: 100 },
        { value: 'income_5', title: '直播坑位费', width: 100 },
        { value: 'income_6', title: '授权收入', width: 100 },
        { value: 'income_7', title: '总推广花费', width: 100 },
        { value: 'income_balance', title: '当期盈亏', width: 100 },
      ],
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()]);
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 5);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      searchCondition: {
        platform_code: '',
        artist_id: null,
        time_value: []
      },
      year: null,
      month: null,
      ym: null,
      chartFlow: null,
      moneyChartOption: {},
      execQtyChartOption: {},
    }
  },
  created() {
    this.searchCondition.artist_id = this.$route.params.artist_id
    this.searchCondition.platform_code = ''
    // this.year = 2021
    this.defaultTime()
    //默认近6个月
    this.initTimeValue()
  },
  methods: {
    init() {
      this.searchCondition.artist_id = this.artistId
      this.searchCondition.platform_code = ''

      this.getData()
      this.initChart()
    },
    moneyFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    numberFormat(value) {
      return numberFormat(value, 0, '.', ',', 'round')
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }

      // if (this.searchCondition.platform_code) {
      //   condition.platform_code = this.searchCondition.platform_code
      // }

      if (this.searchCondition.time_value) {
        condition.start_time = this.searchCondition.time_value[0]
        condition.end_time = this.searchCondition.time_value[1]
      }

      return condition
    },
    rowGridName({ row, rowIndex }) {
      // 把每一行的索引放进row.id
      row.index = rowIndex + 1
    },
    rowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row.id
      row.index = rowIndex + 1
      //
      if (row.is_empty) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index < 1) {
          return
        }
        if (index === 1) {
          sums[index] = '合计:'
          return
        }
        if (['income_total', 'income_1', 'income_2', 'income_3', 'income_4', 'income_5', 'income_6', 'income_7', 'income_balance'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }
          // 格式化数据
          switch (column.property) {
            default:
              sums[index] = this.moneyFormat(sums[index])
              break
          }
        }
      })

      return sums
    },
    changeMonths() {
      this.initTimeValue()
      this.getData()
      this.initChart()
    },
    initTimeValue() {
      try {
        if (this.rangYm.length === 2) {
          let beginMonth = this.rangYm[0];
          let endMonth = this.rangYm[1];
          if (endMonth && endMonth.indexOf('-') > -1) {
            let ymArr = endMonth.split('-')
            let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0);
            let daycount = day.getDate();//最后一天
            this.searchCondition.time_value = [beginMonth + '-01', endMonth + '-' + daycount]
          }
        }
      } catch (e) {
        console.log(e)
      }

    },
    async getData() {
      this.tableData = [];
      let searchCondition = this.handleSearchCondition()
      if (searchCondition.artist_id && searchCondition.end_time && searchCondition.start_time) {
        let { list } = await this.$api.getRevenueItemStatistics(searchCondition)
        this.tableData = list
      }

    },
    defaultTime() {
      //月份选择：默认本年度第一个月到本月
      const end = new Date();
      const start = new Date();
      // start.setMonth(start.getMonth() - 5);
      // let startDate = start.getFullYear() + '-' + (start.getMonth() + 1)
      let startDate = start.getFullYear() + '-01'
      let endDate = end.getFullYear() + '-' + (end.getMonth() + 1)
      this.rangYm = [startDate, endDate]

      //年份选择：默认今年
      this.year = end.getFullYear() + ''
    },
    changeYear() {
      this.initChart()
    },
    //柱状图
    initChart() {
      this.chartFlow = echarts.init(document.getElementById('revenue_chart'))

      let condition = { year: this.year, artist_id: this.searchCondition.artist_id }
      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
      }

      this.loadChartData(condition)
      let erd = elementResizeDetector()
      let _this = this
      erd.listenTo(document.getElementById("echarts-trend-data"), function () {
        _this.chartFlow.resize()
      })
    },
    async loadChartData(condition) {
      this.chartFlow.clear()
      let info = await this.$api.getRevenueItemChartData(condition)

      let option = {
        title: {
          text: this.title,
          top: 0,
          left: 'left',
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            fontSize: 16
          },
          // axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          // }
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          top: 80,
        },
        legend: {
          data: info.legend_data,
          top: 40,
          textStyle: {
            fontWeight: 'bolder',
            fontSize: 16
          }
        },
        xAxis: {
          type: 'category',
          data: info.x_axis,
        },
        yAxis: {
          type: 'value',
        },
        series: {
          data: info.series.data,
          type: info.series.type,
          // showBackground: true,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // },
          itemStyle: {
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                textStyle: { //数值样式
                  color: 'black',
                  fontSize: 12
                }
              },
              color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: '#83bff6' },
                    { offset: 0.5, color: '#188df0' },
                    { offset: 1, color: '#188df0' }
                  ]
              )

          },
        }
      }
      this.chartFlow.setOption(option)
    },
    choicePlatform(val) {
      // console.log('searchCondition', this.searchCondition)
      this.searchCondition.platform_code = val;
      this.initChart()

    }
  }
}
</script>

<style scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.title {
  font-size: 18px;
  font-weight: 800;
}

.platform {
  display: flex;
  flex-direction: row;
}

.platform-title {
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
  line-height: 22px;
  text-align: left;
  flex: 4;
}

#revenue_chart {
  /*border: #0000BB dashed 1px;*/
  height: 600px;
  width: 80%;
  padding: 20px;
  color: #9f00bb;
}

.chart-wrapper {
  padding: 10px 20px;
}
</style>
